import { Controller, useFormContext } from 'react-hook-form';
import { Props as ReactSelectProps } from 'react-select';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import get from 'lodash/get';
import SingleSelect from 'components/Form/Select/SingleSelect';
import MultiSelect from 'components/Form/Select/MultiSelect';
import { OptionType } from 'types/common';

const isOptionType = (a: unknown): a is OptionType => {
  return (a as OptionType)?.value !== undefined;
};

interface SelectProps extends ReactSelectProps {
  options: OptionType[];
  isMulti?: boolean;
  disabled?: boolean;
  openMenuOnFocus?: boolean;
  autoFocus?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  isRequired?: boolean;
  hidden?: boolean;
  showError?: boolean;
  isLoading?: boolean;
  onChangeCallback?: (val: any) => void;
}

function Select({
  label,
  name,
  options,
  placeholder,
  isRequired,
  isMulti,
  disabled,
  openMenuOnFocus,
  autoFocus,
  hidden,
  showError,
  isLoading,
  onChangeCallback,
  ...props
}: SelectProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;
  const SelectComponent = isMulti ? MultiSelect : SingleSelect;

  return (
    <FormControl
      hidden={hidden}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const pickedValues = Array.isArray(value)
            ? options.filter((option) => value.includes(option.value))
            : [options.find((option) => option.value === value)];

          return (
            <SelectComponent
              placeholder={placeholder || 'Wybierz'}
              options={options}
              inputId={name}
              value={pickedValues || null}
              onChange={(newValue: OptionType | OptionType[] | unknown) => {
                if (Array.isArray(newValue)) {
                  onChange(newValue.map((option) => option.value));
                } else if (isOptionType(newValue)) {
                  onChange(newValue.value);
                } else {
                  onChange(newValue);
                }

                if (typeof onChangeCallback === 'function') {
                  if (Array.isArray(newValue)) {
                    const values = newValue.map((option) => option.value);
                    onChangeCallback(values);
                  } else {
                    const { value: val } = newValue as OptionType;
                    onChangeCallback(val);
                  }
                }
              }}
              isMulti={isMulti}
              isDisabled={disabled}
              isLoading={isLoading}
              openMenuOnFocus={openMenuOnFocus}
              autoFocus={autoFocus}
              instanceId={name}
              closeMenuOnSelect={!isMulti}
              withError={Boolean(errorMessage)}
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? null : 'Brak wyników'
              }
              hideSelectedOptions={false}
              menuPosition="fixed"
              {...props}
            />
          );
        }}
      />
      {showError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
Select.defaultProps = {
  label: '',
  isMulti: false,
  disabled: false,
  openMenuOnFocus: false,
  autoFocus: false,
  placeholder: undefined,
  isRequired: false,
  hidden: false,
  showError: true,
  isLoading: false,
  onChangeCallback: null,
};

export default Select;
