import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME, EXTENDED_STALE_TIME } from 'api';

const fetchPromos = async (queryString: string) => {
  const { data: response } = await API.get(APIRoutes.promos.index(queryString));
  return {
    data: response?.data,
    totals: response?.totalCount,
  };
};

const fetchPromo = async (id: string) => {
  const { data } = await API.get(APIRoutes.promos.byId(id));
  return data;
};

const fetchShops = async () => {
  const { data } = await API.get(APIRoutes.promos.shops);
  return data;
};

const useGetPromos = (queryString: string) =>
  useQuery<PromosResponse>(
    ['promos', queryString],
    () => fetchPromos(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetPromo = (id: string) =>
  useQuery<PromoResponse>(['promo', id], () => fetchPromo(id));

const useGetShops = () =>
  useQuery<ShopsResponse>(['shops'], fetchShops, {
    staleTime: EXTENDED_STALE_TIME,
  });

export { useGetPromos, useGetPromo, useGetShops };
