import { Box, Stack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import DatePicker from 'components/Form/Date';
import CouponPoolSelect from 'components/CouponPoolSelect';
import Select from 'components/Form/Select';
import { useGetShops } from 'api/promos';
import { ALL_SHOPS_VALUE } from 'pages/Promos/Form/constants';

function FormBody({ isLoading }: FormBodyProps) {
  const { setValue } = useFormContext();
  const { isFetching, data: { data } = { data: [] } } = useGetShops();

  const shops = useMemo(
    () => [
      { value: ALL_SHOPS_VALUE, label: 'Wszystkie' },
      ...data.map(({ wms_id }) => ({ value: wms_id, label: wms_id })),
    ],
    [data],
  );

  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Input name="name" label="Nazwa" placeholder="Wpisz nazwę" />
        <Input
          name="minOrderGrossAmount"
          label="Minimalna wartość zamówienia"
          placeholder="Wpisz minimalną wartość zamówienia"
          type="number"
          step={0.01}
        />
        <Input
          name="couponsLimit"
          label="Limit kuponów"
          placeholder="Wpisz limit kuponów"
          type="number"
        />
        <DatePicker
          name="activeFrom"
          label="Aktywna od"
          showError
          showTimeInput
        />
        <DatePicker
          name="activeTo"
          label="Aktywna do"
          showError
          showTimeInput
        />
        <Select
          label="Sklep"
          placeholder="Wybierz sklep"
          name="allowedStores"
          isLoading={isFetching}
          options={shops}
          isMulti
          onChangeCallback={(newValue) => {
            const lastValue = newValue[newValue.length - 1];

            if (lastValue === ALL_SHOPS_VALUE) {
              setValue('allowedStores', [ALL_SHOPS_VALUE]);
            } else if (newValue.includes(ALL_SHOPS_VALUE)) {
              setValue(
                'allowedStores',
                newValue.filter((value: string) => value !== ALL_SHOPS_VALUE),
              );
            }
          }}
        />
        <CouponPoolSelect name="sourceCouponPool" showLabel showError />
      </Stack>
    </Box>
  );
}

export default FormBody;
