import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import FormBody from 'pages/Promos/Form/components/FormBody';
import {
  DEFAULT_VALUES,
  BODY_SCHEMA,
  type FormData,
} from 'pages/Promos/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import transformErrors from 'utils/api';

function PromoCreate() {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  const { mutate: createPromo, isLoading } = useMutation({
    mutationKey: 'promosMutation',
    mutationFn: (data: FormData) => API.post(APIRoutes.promos.index(), data),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Promocja została pomyślnie utworzona!');
      queryClient.invalidateQueries('promos');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas tworzenia promocji');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => createPromo(data));

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="promo-creator"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="promo-creator"
        />
      </DrawerFooter>
    </>
  );
}

export default PromoCreate;
