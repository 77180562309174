import { useIsMutating } from 'react-query';
import MainLayout from 'components/Layout/MainLayout';
import Table from 'pages/Promos/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetPromos } from 'api/promos';
import Add from 'components/Icons/Add';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import { useFormDrawer } from 'context/FormDrawerContext';
import PromoCreate from 'pages/Promos/Form/Create';

function Promos() {
  const { setFormDrawer } = useFormDrawer();
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const isMutating = useIsMutating({
    mutationKey: ['promosMutation'],
  });

  const {
    isFetching,
    data: { data: promos, totals } = { data: [], totals: 0 },
  } = useGetPromos(queryString);
  const showLoading = isMutating || isFetching;

  return (
    <MainLayout
      title="Promocje"
      headerButtons={[
        {
          label: 'Promocja',
          leftIcon: <Add />,
          onClick: () =>
            setFormDrawer({
              title: 'Nowa promocja',
              content: <PromoCreate />,
            }),
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={promos} />}
      <Footer totals={totals} />
    </MainLayout>
  );
}

export default Promos;
