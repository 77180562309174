import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import FormBody from 'pages/Promos/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  type FormData,
} from 'pages/Promos/Form/constants';
import { API, APIRoutes } from 'api';
import FormButtons from 'components/FormButtons';
import { useFormWithSchema } from 'utils/formHooks';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { useGetPromo } from 'api/promos';
import parseDefaultValues from 'pages/Promos/Form/Edit/parseDefaultValues';

function PromoEdit({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const { data: { data: promo } = {}, isFetching } = useGetPromo(id);

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const { mutate: editPromo, isLoading: isEditing } = useMutation({
    mutationKey: 'promosMutation',
    mutationFn: (data: FormData) => API.patch(APIRoutes.promos.byId(id), data),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Zmiany w promocji zostały zastosowane');
      queryClient.invalidateQueries('promos');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas edycji promocji');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) => editPromo(data));

  useEffect(() => {
    if (promo) reset({ ...DEFAULT_VALUES, ...parseDefaultValues(promo) });
  }, [promo, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="promo-editor"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} isEditMode />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="promo-editor"
        />
      </DrawerFooter>
    </>
  );
}

export default PromoEdit;
