import { Box } from '@chakra-ui/react';
import { Route, Outlet, Navigate } from 'react-router-dom';
import map from 'lodash/map';
import PublicLayout from 'components/Layout/PublicLayout';
import Layout from 'components/Layout';
import ROUTES from 'app/routes';

import Login from 'pages/Login';
import CouponsListing from 'pages/Coupons/Listing';
import CouponStatistics from 'pages/Coupons/Statistics';
import CouponsCreate from 'pages/Coupons/Form/Create';
import UsersListing from 'pages/Users/Listing';
import QrCodesGenerator from 'pages/QrCodes/Generator';
import CouponPoolsListing from 'pages/CouponPools/Listing';
import CouponPoolCreate from 'pages/CouponPools/Form/Create';
import CouponPoolEdit from 'pages/CouponPools/Form/Edit';
import SubscriptionCouponsListing from 'pages/SubscriptionCoupons/Listing';
import ActivationCodesListing from 'pages/ActivationCodes/Listing';
import ExposedEndpointsListing from 'pages/ExposedEndpoints/Listing';
import ExposedEndpointPreview from 'pages/ExposedEndpoints/Preview';
import PreviewLayout from 'components/Layout/PreviewLayout';
import PromosListing from 'pages/Promos/Listing';

export const renderRoutes = (routes: any[]) =>
  map(routes, ({ layout: RouteLayout = Box, ...route }, idx: number) => {
    const Component =
      typeof route.element === 'string' ? route.element : <route.element />;
    return (
      <Route
        key={`route-${route.path}-${idx}`}
        path={route.path}
        element={
          <RouteLayout>
            {Component}
            <Outlet />
          </RouteLayout>
        }
      >
        {route.children ? renderRoutes(route.children) : null}
      </Route>
    );
  });

function DefaultRedirect() {
  return <Navigate to={ROUTES.auth.login} />;
}

export const PUBLIC_ROUTES = [
  { element: DefaultRedirect, path: '*', layout: PublicLayout },
  { element: Login, path: ROUTES.auth.login, layout: PublicLayout },
  {
    element: ExposedEndpointPreview,
    path: ROUTES.exposedEndpoints.preview,
    layout: PreviewLayout,
  },
];

export const PRIVATE_ROUTES = [
  {
    element: CouponsListing,
    path: ROUTES.coupons.base,
    layout: Layout,
  },
  {
    element: CouponsCreate,
    path: ROUTES.coupons.create,
    layout: Layout,
  },
  {
    element: CouponStatistics,
    path: ROUTES.coupons.statistics,
    layout: Layout,
  },
  {
    element: UsersListing,
    path: ROUTES.users,
    layout: Layout,
  },
  {
    element: QrCodesGenerator,
    path: ROUTES.qrCodes.generator,
    layout: Layout,
  },
  {
    element: CouponPoolsListing,
    path: ROUTES.couponPools.base,
    layout: Layout,
  },
  {
    element: CouponPoolCreate,
    path: ROUTES.couponPools.create,
    layout: Layout,
  },
  {
    element: CouponPoolEdit,
    path: ROUTES.couponPools.edit,
    layout: Layout,
  },
  {
    element: SubscriptionCouponsListing,
    path: ROUTES.subscriptionCoupons,
    layout: Layout,
  },
  {
    element: ActivationCodesListing,
    path: ROUTES.activationCodes,
    layout: Layout,
  },
  {
    element: ExposedEndpointsListing,
    path: ROUTES.exposedEndpoints.base,
    layout: Layout,
  },
  {
    element: PromosListing,
    path: ROUTES.promos,
    layout: Layout,
  },
];
