const parseDefaultValues = (promo: Promo) => {
  const result: Record<string, unknown> = { ...promo };

  result.activeFrom = promo?.activeFrom ? new Date(promo.activeFrom) : null;
  result.activeTo = promo?.activeTo ? new Date(promo.activeTo) : null;

  return result;
};

export default parseDefaultValues;
