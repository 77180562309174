import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const ALL_SHOPS_VALUE = 'all';

const DEFAULT_VALUES = {
  name: '',
  minOrderGrossAmount: 99999,
  couponsLimit: 99999,
  activeFrom: undefined,
  activeTo: undefined,
  allowedStores: [ALL_SHOPS_VALUE],
  sourceCouponPool: '',
};

const BODY_SCHEMA = yup.object({
  name: yup.string().required(ERROR_MESSAGES.required),
  minOrderGrossAmount: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  couponsLimit: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  activeFrom: yup.date().nullable().required(ERROR_MESSAGES.required),
  activeTo: yup.date().nullable().required(ERROR_MESSAGES.required),
  allowedStores: yup.array().of(yup.string()).required(ERROR_MESSAGES.required),
  sourceCouponPool: yup.string().required(ERROR_MESSAGES.required),
});

type FormData = yup.InferType<typeof BODY_SCHEMA>;

export { DEFAULT_VALUES, BODY_SCHEMA, ALL_SHOPS_VALUE };
export type { FormData };
